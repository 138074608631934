// ----------------------------------------------------------------------

import { sortDataCalculatorResponses } from '@/lib/utils';
import { gql, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useState } from 'react';
import {
  AreaUnitsEnum,
  DataCalculatorResponseFragmentFragment,
  DeleteLocationInput,
  DeleteLocationPayload,
  LocationFragmentFragment,
  LocationsViewQueryQuery,
  MeasurementFragmentFragment
} from 'src/__apolloGenerated__/graphql';
import AssetMeasurementsCard from 'src/components/carbon/molecules/AssetMeasurementsCard';
import AssetManager from 'src/components/carbon/organisms/AssetManager';
import { ROUTES } from 'src/config';
import useAssetStore, {
  AssetStoreType
} from 'src/hooks/store/useAssetStore';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import { fEnum, fPrecision } from 'src/utils/format';
import { dataCalculatorResponseFragment } from 'src/views/Scope1View';
import CreateLocationDialog from './CreateLocationDialog';

type LocationType = LocationFragmentFragment & {
  dataCalculators: DataCalculatorResponseFragmentFragment[];
};

const fAreaUnit = (unit) => {
  if (unit === AreaUnitsEnum.SqFt) {
    return 'sqft';
  } else if (unit === AreaUnitsEnum.SqM) {
    return 'm²';
  }
};
export const renderIconCard = (
  iconPath: string,
  name?: string,
  onClick?: () => void
) => {
  return (
    <div
      className="flex max-w-[110px] flex-col flex-nowrap"
      onClick={onClick ? onClick : null}
    >
      <div
        className={
          'max-w-[110px] rounded-md transition-all  ' +
          (onClick &&
            'cursor-pointer border hover:shadow-md hover:ring-1  hover:ring-primary/50 ')
        }
      >
        <img src={iconPath} className="grayscale" />
      </div>
      {name && <p className="mt-sm">{name}</p>}
    </div>
  );
};

export const getLocationDescription = (location) => {
  const area = location?.area
    ? `${fPrecision(location.area, 2)} ${fAreaUnit(location.areaUnit)}`
    : null;
  const descriptionParts = [];
  if (location?.category) {
    descriptionParts.push(fEnum(location?.category));
  }
  if (location?.subcategory) {
    descriptionParts.push(fEnum(location?.subcategory));
  }
  if (area) {
    descriptionParts.push(area);
  }
  return descriptionParts.filter(Boolean).join(' • ');
};

export const getSecondaryLocationDescription = (location) => {
  const addressParts = [];
  if (location?.addressLine1) {
    addressParts.push(location?.addressLine1);
  }
  if (location?.city) {
    addressParts.push(location?.city);
  }
  if (location?.state) {
    addressParts.push(fEnum(location?.state));
  }
  if (location?.country) {
    addressParts.push(fEnum(location?.country));
  }
  if (location?.postalCode) {
    addressParts.push(location?.postalCode);
  }
  return addressParts.filter(Boolean).join(', ');
};

export default function LocationsView() {
  const router = useRouter();
  const { setActiveAssetType, setActiveAssetIdentifier } =
    useAssetStore((store: AssetStoreType) => ({
      setActiveAssetType: store.setActiveAssetType,
      setActiveAssetIdentifier: store.setActiveAssetIdentifier
    }));

  const { activeReportingPeriodId }: SettingsStoreType =
    useSettingsStore();

  const { data, loading } = useQuery<LocationsViewQueryQuery>(
    gql`
      ${dataCalculatorResponseFragment}
      query LocationsViewQuery(
        $startDate: DateTime
        $endDate: DateTime
        $states: [MeasurementStatesEnum]
        $reportingPeriodIdentifier: String
      ) {
        locations {
          ok
          errors {
            ...ErrorsFragment
          }
          data {
            ...LocationFragment
            dataCalculators(
              reportingPeriodIdentifier: $reportingPeriodIdentifier
            ) {
              ...DataCalculatorResponseFragment
              measurements {
                ...MeasurementFragment
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        reportingPeriodIdentifier: activeReportingPeriodId
      }
    }
  );

  const locations = (data?.locations?.data as LocationType[]) || [];

  const [createLocationDialogOpen, setCreateLocationDialogOpen] =
    useState(false);
  const [initialLocation, setInitialLocation] =
    useState<LocationFragmentFragment | null>(null);

  const [areYouSureDialogOpen, setAreYouSureDialogOpen] =
    useState(false);

  const { mutate: deleteLocation, loading: deletingLocation } =
    useBackendMutation<
      { input: DeleteLocationInput },
      DeleteLocationPayload
    >({
      mutation: gql`
        mutation DeleteLocation($input: DeleteLocationInput!) {
          deleteLocation(input: $input) {
            ok
            errors {
              ...ErrorsFragment
            }
          }
        }
      `,
      callbacks: {
        onSuccess: () => {
          setAreYouSureDialogOpen(false);
        }
      }
    });

  const renderIconCard = (
    iconPath: string,
    name: string,
    onClick: () => void
  ) => {
    return (
      <div
        className="flex max-w-[110px] flex-col flex-nowrap"
        onClick={onClick}
      >
        <div className="max-w-[110px] cursor-pointer rounded-md border transition-all hover:shadow-md hover:ring-1 hover:ring-primary/50">
          <img src={iconPath} className="grayscale" />
        </div>
        <p className="mt-sm">{name}</p>
      </div>
    );
  };

  return (
    <>
      <AssetManager<LocationType>
        type="location"
        assets={locations}
        onEdit={(assetIdentifier) => {
          const location = locations.find(
            (location) => location.identifier === assetIdentifier
          );
          setCreateLocationDialogOpen(true);
          setInitialLocation(location);
        }}
        beforeYouContinueMessage={
          locations?.length > 0
            ? null
            : `It looks like you have not added any locations yet. Your inventory will not be complete until you add all of your business' locations.`
        }
        getAssetDetails={(location) => ({
          identifier: location?.identifier,
          name: location?.name,
          description: getLocationDescription(location),
          secondaryDescription:
            getSecondaryLocationDescription(location),
          renderIcon: location?.icon
            ? () => <img src={location?.icon} className="grayscale" />
            : undefined,
          detailSections: [
            {
              title: 'Emissions',
              renderContent: () => {
                //Sort responses so that the ones without measurements are first
                const responses = sortDataCalculatorResponses(
                  location?.dataCalculators
                );
                return responses?.map((response, idx) => {
                  return (
                    <div className={idx !== 0 && 'mt-md'}>
                      <AssetMeasurementsCard
                        calculatorType={response.calculatorType}
                        measurements={
                          response.measurements as MeasurementFragmentFragment[]
                        }
                        asset={location}
                      />
                    </div>
                  );
                });
              }
            },
            {
              title: 'Vehicles',
              renderContent:
                location?.vehicles?.length > 0
                  ? () => (
                      <div className="flex gap-4">
                        {location?.vehicles?.map((vehicle) => {
                          const name =
                            vehicle.name +
                            (Number(vehicle.numberOfUnits) > 1
                              ? ` x${vehicle.numberOfUnits}`
                              : '');
                          return (
                            <div key={vehicle.identifier}>
                              {renderIconCard(
                                vehicle.icon,
                                name,
                                () => {
                                  router.push(
                                    ROUTES.ORGANIZATION.VEHICLES
                                  );
                                  setActiveAssetIdentifier(
                                    vehicle.identifier
                                  );
                                  setActiveAssetType('vehicle');
                                }
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )
                  : () => (
                      <div className="flex flex-col flex-nowrap">
                        <p>No vehicles yet.</p>
                      </div>
                    )
            },
            {
              title: 'Equipment',
              renderContent:
                location?.equipment?.length > 0
                  ? () => (
                      <div className="flex gap-4">
                        {location?.equipment?.map((equipment) => {
                          const name =
                            equipment.name +
                            (Number(equipment.numberOfUnits) > 1
                              ? ` x${equipment.numberOfUnits}`
                              : '');
                          return (
                            <div key={equipment.identifier}>
                              {renderIconCard(
                                equipment.icon,
                                name,
                                () => {
                                  router.push(
                                    ROUTES.ORGANIZATION.EQUIPMENT
                                  );
                                  setActiveAssetIdentifier(
                                    equipment.identifier
                                  );
                                  setActiveAssetType('equipment');
                                }
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )
                  : () => (
                      <div className="flex flex-col flex-nowrap">
                        <p>No equipment yet.</p>
                      </div>
                    )
            }
          ]
        })}
        onDelete={(assetIdentifier) => {
          deleteLocation({
            variables: {
              input: {
                locationIdentifier: assetIdentifier
              }
            },
            awaitRefetchQueries: true,
            refetchQueries: ['LocationsViewQuery']
          });
        }}
        deleteLoading={deletingLocation}
        areYouSureDialogOpenState={[
          areYouSureDialogOpen,
          setAreYouSureDialogOpen
        ]}
        loading={loading}
        setCreateDialogOpen={setCreateLocationDialogOpen}
        bottomNavButtonText="Vehicles"
        bottomNavOnClick={() => {
          router.push(ROUTES.ORGANIZATION.VEHICLES);
        }}
      />
      <CreateLocationDialog
        open={createLocationDialogOpen}
        onOpenChange={(open) => {
          setCreateLocationDialogOpen(open);
          if (!open) {
            setInitialLocation(null);
          }
        }}
        initialLocation={initialLocation}
      />
    </>
  );
}
