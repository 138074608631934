import dayjs from 'dayjs';
import {
  BarChart,
  Calendar,
  CircleCheckBig,
  CircleDashed,
  Cloud,
  RefreshCcw,
  Ruler
} from 'lucide-react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import {
  DeleteMeasurementInput,
  DeleteMeasurementPayload,
  EditMeasurementInput,
  EditMeasurementPayload,
  MeasurementFragmentFragment,
  MeasurementIntervalUnitsEnum,
  MeasurementStatesEnum
} from 'src/__apolloGenerated__/graphql';
import { getUnitTypeFromUnit } from 'src/backend/@types';
import CarbonUnit from 'src/components/carbon/atoms/CarbonUnit';
import { DatePicker } from 'src/components/core/atoms/DatePicker';
import Input from 'src/components/core/atoms/Input';
import Skeleton from 'src/components/core/atoms/Skeleton';
import AreYouSureDialog from 'src/components/core/molecules/AreYouSureDialog';
import SingleSelect from 'src/components/core/molecules/SingleSelect';
import { Button } from 'src/components/shad-base/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from 'src/components/shad-base/tooltip';
import { ROUTES } from 'src/config';
import { useToast } from 'src/hooks/shad-base/useToast';
import useMeasureStore, {
  MeasureStoreType
} from 'src/hooks/store/useMeasureStore';
import useStrategiesStore, {
  StrategiesStoreType
} from 'src/hooks/store/useStrategiesStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import {
  DELETE_MEASUREMENT,
  EDIT_MEASUREMENT
} from 'src/hooks/useBackendMutation/mutations';
import {
  fEnum,
  fLocalDate,
  fOwnershipFraction
} from 'src/utils/format';
import { MEASUREMENT_STATE_COLOURS } from 'src/views/MeasureView/detail/MeasurementDetailView';
import EditMeasurementFrequencyDialog from 'src/views/MeasureView/detail/MeasurementDetailView/EditMeasurementFrequencyDialog';

export const getFormattedMeasurementInterval = (
  intervalValue: number,
  intervalUnit: MeasurementIntervalUnitsEnum
) => {
  if (intervalValue === 1) {
    return fEnum(intervalUnit).replace('s', '').toLowerCase();
  } else {
    return `${intervalValue} ${fEnum(intervalUnit).toLowerCase()}`;
  }
};
export const toolTipIcon = (icon: React.ReactNode, text: string) => {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={100}>
        <TooltipTrigger asChild>
          <div className="cursor-pointer">{icon}</div>
        </TooltipTrigger>
        <TooltipContent side="left">{text}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
export default function MeasurementDetailViewPanelContent({
  measurement,
  editingDisabled,
  loadingMeasurement
}: {
  measurement: MeasurementFragmentFragment;
  editingDisabled: boolean;
  loadingMeasurement: boolean;
}) {
  const router = useRouter();
  const { toast } = useToast();
  const {
    setActiveNode,
    setIsDetailViewExpanded,
    selectedStartDate,
    selectedEndDate,
    selectedFilters
  }: MeasureStoreType = useMeasureStore();

  const { setActiveStrategy } = useStrategiesStore(
    (store: StrategiesStoreType) => ({
      setActiveStrategy: store.setActiveStrategy
    })
  );

  const [
    editMeasurementFrequencyDialogOpen,
    setEditMeasurementFrequencyDialogOpen
  ] = useState(false);
  const [ownershipFraction, setOwnershipFraction] = useState(
    fOwnershipFraction(measurement?.ownershipFraction)
  );
  const [areYouSureDialogOpen, setAreYouSureDialogOpen] =
    useState(false);

  useEffect(() => {
    setOwnershipFraction(
      fOwnershipFraction(measurement?.ownershipFraction)
    );
  }, [measurement]);

  const [error, setError] = useState(null);

  const { mutate: deleteMeasurement, loading: deletingMeasurement } =
    useBackendMutation<
      { input: DeleteMeasurementInput },
      DeleteMeasurementPayload
    >({
      mutation: DELETE_MEASUREMENT,
      callbacks: {
        onSuccess: () => {
          setIsDetailViewExpanded(false);
          setActiveNode({
            nodeId: null,
            type: null,
            path: null
          });
          toast({
            title: 'Success.',
            description: 'Measurement deleted.',
            variant: 'success'
          });
          setAreYouSureDialogOpen(false);
        }
      }
    });

  const {
    mutate: editMeasurementDate,
    loading: editingMeasurementDate
  } = useBackendMutation<
    { input: EditMeasurementInput },
    EditMeasurementPayload
  >({
    mutation: EDIT_MEASUREMENT
  });

  const {
    mutate: editMeasurementState,
    loading: editingMeasurementState
  } = useBackendMutation<
    { input: EditMeasurementInput },
    EditMeasurementPayload
  >({
    mutation: EDIT_MEASUREMENT
  });

  const {
    mutate: editMeasurementOwnership,
    loading: editingMeasurementOwnership
  } = useBackendMutation<
    { input: EditMeasurementInput },
    EditMeasurementPayload
  >({
    mutation: EDIT_MEASUREMENT
  });

  const { mutate: markAsAvoided, loading: markingAsAvoided } =
    useBackendMutation({
      mutation: EDIT_MEASUREMENT,
      callbacks: {
        onSuccess: () => {
          toast({
            title: 'Success.',
            description: 'Measurement marked as avoided.',
            variant: 'success'
          });
        }
      }
    });

  let canEditMeasurementFrequency = false;
  if (!measurement?.isRecurring) {
    canEditMeasurementFrequency = true;
  } else if (
    measurement?.previousMeasurements?.length === 0 &&
    measurement?.subsequentMeasurements?.length === 0
  ) {
    canEditMeasurementFrequency = true;
  }

  const permissions = measurement?.branch?.userPermissions;
  const isDateRange = measurement?.startDate && measurement?.endDate;
  return (
    <>
      <div className="flex h-full flex-col flex-nowrap justify-between">
        <div>
          <div className="flex flex-col flex-nowrap">
            <p className="text-muted">Details</p>
            {/* Emissions */}
            <div className="mt-sm flex flex-nowrap items-center">
              {toolTipIcon(
                <Cloud className="h-4 w-4" />,
                'Emissions'
              )}
              <div>
                {loadingMeasurement ? (
                  <div className="ml-3 mt-2">
                    <Skeleton width={120} height={30} />
                  </div>
                ) : (
                  <div className="ml-3 p-sm">
                    <CarbonUnit
                      emissionValueInKg={measurement?.kgCo2e}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* Emissions */}
            <div className="mt-sm flex flex-nowrap items-center">
              {toolTipIcon(
                measurement?.emissionFactor?.name ? (
                  <CircleCheckBig className="h-4 w-4 text-primary" />
                ) : (
                  <CircleDashed className="h-4 w-4 text-muted" />
                ),
                'Emission Factor'
              )}
              <div>
                {loadingMeasurement ? (
                  <div className="ml-3 mt-2">
                    <Skeleton width={120} height={30} />
                  </div>
                ) : (
                  <p className="ml-3 p-sm">
                    {measurement?.emissionFactor?.name ||
                      'No Emission Factor'}
                  </p>
                )}
              </div>
            </div>
            {/* Scope */}
            <div className="mt-sm flex flex-nowrap items-center">
              {toolTipIcon(<BarChart className="h-4 w-4" />, 'Scope')}
              <div>
                {loadingMeasurement ? (
                  <div className="ml-3 mt-2">
                    <Skeleton width={120} height={30} />
                  </div>
                ) : (
                  <p className="ml-3 text-nowrap p-sm">
                    Scope {measurement?.scope}
                  </p>
                )}
              </div>
            </div>

            {/* Unit Type */}
            <div className="mt-sm flex flex-nowrap items-center">
              {toolTipIcon(
                <Ruler className="h-4 w-4" />,
                'Unit type'
              )}
              <div>
                {loadingMeasurement ? (
                  <div className="ml-3 mt-2">
                    <Skeleton width={120} height={30} />
                  </div>
                ) : (
                  <p className="ml-3 text-nowrap p-sm">
                    {fEnum(
                      getUnitTypeFromUnit(measurement?.valueUnit)
                    )}
                  </p>
                )}
              </div>
            </div>
            <p className="mb-sm mt-lg text-muted">Properties</p>
            {/* Date */}
            {editingDisabled ? (
              <div className="mt-sm flex flex-nowrap items-center">
                <div className="flex h-5 w-5 items-center justify-center">
                  {toolTipIcon(
                    <Calendar className={'h-4 w-4 '} />,
                    'Date of Measurement'
                  )}
                </div>
                <div>
                  {loadingMeasurement ? (
                    <div className="ml-sm">
                      <Skeleton width={120} height={30} />
                    </div>
                  ) : (
                    <>
                      {measurement?.startDate &&
                      measurement.endDate ? (
                        <p className="ml-sm rounded-md p-sm">
                          {fLocalDate(measurement?.startDate)} -{' '}
                          {fLocalDate(measurement?.endDate)}
                        </p>
                      ) : (
                        <p className="ml-sm rounded-md p-sm ">
                          {fLocalDate(measurement?.dateOfMeasurement)}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <DatePicker
                isRange={isDateRange}
                required={true}
                dateRange={
                  isDateRange
                    ? {
                        startDate: dayjs(
                          measurement?.startDate
                        ).toDate(),
                        endDate: dayjs(measurement?.endDate).toDate()
                      }
                    : undefined
                }
                setDateRange={
                  isDateRange
                    ? (dateRange) => {
                        editMeasurementDate({
                          variables: {
                            input: {
                              branchIdentifier:
                                measurement?.branch?.identifier,
                              measurementIdentifier:
                                measurement?.identifier,
                              measurement: {
                                startDate: dateRange.startDate,
                                endDate: dateRange.endDate
                              }
                            }
                          }
                        });
                      }
                    : undefined
                }
                renderCustomAnchorEl={() => {
                  return (
                    <div className="mt-sm flex flex-nowrap items-center">
                      <div className="flex h-5 w-5 items-center justify-center">
                        {toolTipIcon(
                          <Calendar className={'h-4 w-4 '} />,
                          'Date of Measurement'
                        )}
                      </div>
                      <div>
                        {loadingMeasurement ||
                        editingMeasurementDate ? (
                          <div className="ml-sm">
                            <Skeleton width={120} height={30} />
                          </div>
                        ) : (
                          <p className="ml-sm cursor-pointer rounded-md p-sm text-start hover:bg-card">
                            {isDateRange
                              ? `${fLocalDate(
                                  measurement?.startDate
                                )} - ${fLocalDate(
                                  measurement?.endDate
                                )}`
                              : fLocalDate(
                                  measurement?.dateOfMeasurement
                                )}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                }}
                date={
                  isDateRange
                    ? undefined
                    : dayjs(measurement?.dateOfMeasurement).toDate()
                }
                setDate={
                  isDateRange
                    ? undefined
                    : (date) => {
                        editMeasurementDate({
                          variables: {
                            input: {
                              branchIdentifier:
                                measurement?.branch?.identifier,
                              measurementIdentifier:
                                measurement?.identifier,
                              measurement: {
                                dateOfMeasurement: date
                              }
                            }
                          }
                        });
                      }
                }
              />
            )}
            {/* State */}
            <SingleSelect
              options={Object.values(MeasurementStatesEnum).map(
                (state) => {
                  return {
                    key: state,
                    label: fEnum(state)
                  };
                }
              )}
              value={measurement?.state}
              setValue={(value) => {
                editMeasurementState({
                  variables: {
                    input: {
                      branchIdentifier:
                        measurement?.branch?.identifier,
                      measurementIdentifier: measurement?.identifier,
                      measurement: {
                        state: value
                      }
                    }
                  }
                });
              }}
              renderCustomTrigger={() => {
                return (
                  <div className="mt-sm flex flex-nowrap items-center">
                    <div className="flex h-5 w-5 items-center justify-center">
                      {toolTipIcon(
                        <div
                          className={
                            'h-4 w-4 rounded-full ' +
                            (loadingMeasurement
                              ? 'bg-muted/20'
                              : MEASUREMENT_STATE_COLOURS[
                                  measurement?.state
                                ])
                          }
                        />,
                        'State'
                      )}
                    </div>
                    <div>
                      {loadingMeasurement ||
                      editingMeasurementState ? (
                        <div className="ml-sm">
                          <Skeleton width={120} height={30} />
                        </div>
                      ) : (
                        <p className="ml-sm cursor-pointer rounded-md p-sm hover:bg-card">
                          {measurement?.state ===
                          MeasurementStatesEnum.ToReview
                            ? 'Needs Review'
                            : fEnum(measurement?.state)}
                        </p>
                      )}
                    </div>
                  </div>
                );
              }}
            />

            {/* Measurement Frequency */}
            <div className="mt-sm flex flex-nowrap items-center">
              {toolTipIcon(
                measurement?.avoidedAfterEndDate ? (
                  <CircleCheckBig className="h-4 w-4 text-success" />
                ) : (
                  <RefreshCcw className="h-4 w-4" />
                ),
                measurement?.avoidedAfterEndDate
                  ? 'Emission Source Avoided'
                  : 'Frequency'
              )}
              <div>
                {loadingMeasurement ? (
                  <div className="ml-3 mt-2">
                    <Skeleton width={120} height={30} />
                  </div>
                ) : (
                  <p
                    className={
                      'ml-3 text-nowrap rounded-md p-sm ' +
                      (canEditMeasurementFrequency &&
                      !measurement?.avoidedAfterEndDate
                        ? 'cursor-pointer hover:bg-card'
                        : '')
                    }
                    onClick={
                      canEditMeasurementFrequency &&
                      !measurement?.avoidedAfterEndDate
                        ? () =>
                            setEditMeasurementFrequencyDialogOpen(
                              true
                            )
                        : null
                    }
                  >
                    {measurement?.avoidedAfterEndDate
                      ? 'Avoided'
                      : measurement?.isRecurring
                        ? `Recurs every ${getFormattedMeasurementInterval(
                            measurement?.intervalValue,
                            measurement?.intervalUnit
                          )}`
                        : 'One-time'}
                  </p>
                )}
              </div>
            </div>

            {/* Strategy */}
            {measurement?.strategy?.title && (
              <div className="mt-xl text-muted">
                <p className="mb-sm">Associated Strategy</p>
                <p
                  className="cursor-pointer text-primary hover:underline"
                  onClick={() => {
                    router.push(ROUTES.MITIGATE.STRATEGIES);
                    setActiveStrategy({
                      identifier: measurement?.strategy?.identifier,
                      name: measurement?.strategy?.title
                    });
                  }}
                >
                  {measurement?.strategy?.title}
                </p>
              </div>
            )}
            {/* Ownership */}
            <div className="mt-xl text-muted">
              <p>Financial Ownership</p>
            </div>
            <div className="mt-sm flex w-full flex-nowrap items-center">
              <div className="w-full">
                {loadingMeasurement ? (
                  <div className="ml-3 mt-2">
                    <Skeleton width={120} height={30} />
                  </div>
                ) : editingDisabled ? (
                  <p className="mt-md">{ownershipFraction}%</p>
                ) : (
                  <Input
                    noBorder
                    value={ownershipFraction}
                    type="number"
                    onChange={(e) => {
                      setError(null);
                      setOwnershipFraction(e.target.value);
                    }}
                    disabled={editingMeasurementOwnership}
                    onBlur={() => {
                      const fraction =
                        Number(ownershipFraction) / 100;
                      if (fraction <= 0 || fraction > 1) {
                        setError(
                          'Ownership percentage must be between 0 and 100%'
                        );
                        setOwnershipFraction(
                          fOwnershipFraction(
                            measurement?.ownershipFraction
                          )
                        );
                      } else {
                        editMeasurementOwnership({
                          variables: {
                            input: {
                              branchIdentifier:
                                measurement?.branch?.identifier,
                              measurementIdentifier:
                                measurement?.identifier,
                              measurement: {
                                ownershipFraction: fraction
                              }
                            }
                          }
                        });
                      }
                    }}
                    endAdornment={<p className="text-muted">%</p>}
                  />
                )}
              </div>
            </div>
            {error && (
              <p className="body2 mt-sm text-destructive">{error}</p>
            )}
          </div>
        </div>

        {/* Delete */}
        <div className="flex w-full flex-col flex-nowrap gap-sm">
          {measurement?.isRecurring &&
            !measurement?.avoidedAfterEndDate && (
              <div className="w-full">
                <Button
                  variant="success"
                  className="w-full"
                  loading={markingAsAvoided}
                  onClick={() => {
                    markAsAvoided({
                      variables: {
                        input: {
                          branchIdentifier:
                            measurement?.branch?.identifier,
                          measurementIdentifier:
                            measurement?.identifier,
                          measurement: {
                            avoidedAfterEndDate: true
                          }
                        }
                      }
                    });
                  }}
                >
                  Mark as Avoided
                </Button>
              </div>
            )}
          {permissions?.delete && (
            <div className="w-full">
              <Button
                variant="destructive"
                className="w-full"
                onClick={() => {
                  setAreYouSureDialogOpen(true);
                }}
              >
                Delete Measurement
              </Button>
            </div>
          )}
        </div>
      </div>
      <EditMeasurementFrequencyDialog
        open={editMeasurementFrequencyDialogOpen}
        onOpenChange={setEditMeasurementFrequencyDialogOpen}
        measurement={measurement}
      />
      <AreYouSureDialog
        open={areYouSureDialogOpen}
        onOpenChange={setAreYouSureDialogOpen}
        deleteLoading={deletingMeasurement}
        onDeleteConfirm={() => {
          deleteMeasurement({
            variables: {
              input: {
                measurementIdentifier: measurement?.identifier
              },
              startDate: selectedStartDate
                ? dayjs(selectedStartDate).toDate()
                : null,
              endDate: selectedEndDate
                ? dayjs(selectedEndDate).toDate()
                : null,
              states: selectedFilters
            }
          });
        }}
      />
    </>
  );
}
