import { create } from 'zustand';

export type ActiveStrategyType = {
  identifier: string;
  name: string;
};
export type ActiveListViewType =
  | 'active'
  | 'your-templates'
  | 'explore';

export type StrategyDialogModeType = 'active' | 'template';

export type StrategiesStoreType = {
  activeListView: ActiveListViewType;
  setActiveListView: (view: ActiveListViewType) => void;
  activeStrategy: ActiveStrategyType | null;
  setActiveStrategy: (strategy: ActiveStrategyType) => void;
  activeStrategyTemplate: ActiveStrategyType | null;
  setActiveStrategyTemplate: (strategy: ActiveStrategyType) => void;
  strategyDialogMode: StrategyDialogModeType;
  setStrategyDialogMode: (mode: StrategyDialogModeType) => void;
  clearActive: () => void;
};

const useStrategiesStore = create<StrategiesStoreType>((set) => ({
  activeListView: 'active',
  setActiveListView: (view) => set({ activeListView: view }),
  activeStrategy: null,
  setActiveStrategy: (strategy) =>
    set({ activeStrategy: strategy, activeStrategyTemplate: null }),
  activeStrategyTemplate: null,
  setActiveStrategyTemplate: (strategy) =>
    set({ activeStrategyTemplate: strategy, activeStrategy: null }),
  clearActive: () =>
    set({ activeStrategy: null, activeStrategyTemplate: null }),
  strategyDialogMode: 'active',
  setStrategyDialogMode: (mode) => set({ strategyDialogMode: mode })
}));

export default useStrategiesStore;
