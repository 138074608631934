// ----------------------------------------------------------------------

import { sortDataCalculatorResponses } from '@/lib/utils';
import { gql, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useState } from 'react';
import {
  DataCalculatorResponseFragmentFragment,
  DeleteVehicleInput,
  DeleteVehiclePayload,
  MeasurementFragmentFragment,
  VehicleFragmentFragment,
  VehiclesViewQueryQuery
} from 'src/__apolloGenerated__/graphql';
import AssetMeasurementsCard from 'src/components/carbon/molecules/AssetMeasurementsCard';
import AssetManager from 'src/components/carbon/organisms/AssetManager';
import { ROUTES } from 'src/config';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import { fEnum } from 'src/utils/format';
import { LocationCard } from 'src/views/EquipmentView';
import {
  getLocationDescription,
  getSecondaryLocationDescription
} from 'src/views/LocationsView';
import { dataCalculatorResponseFragment } from 'src/views/Scope1View';
import CreateVehiclesDialog from 'src/views/VehiclesView/CreateVehiclesDialog';

type VehicleType = VehicleFragmentFragment & {
  dataCalculators: DataCalculatorResponseFragmentFragment[];
};

export const getVehicleDescription = (
  vehicle: VehicleFragmentFragment
) => {
  return [
    fEnum(vehicle?.vehicleType) || null,
    fEnum(vehicle?.fuelType) || null
  ]
    .filter(Boolean)
    .join(' • ');
};

export const getSecondaryVehicleDescription = (
  vehicle: VehicleFragmentFragment
) => {
  return [
    vehicle?.make || null,
    vehicle?.model || null,
    vehicle?.year || null
  ]
    .filter(Boolean)
    .join(' ');
};

export default function VehiclesView() {
  const { activeReportingPeriodId }: SettingsStoreType =
    useSettingsStore();

  const { data, loading } = useQuery<VehiclesViewQueryQuery>(
    gql`
      ${dataCalculatorResponseFragment}
      query VehiclesViewQuery(
        $startDate: DateTime
        $endDate: DateTime
        $states: [MeasurementStatesEnum]
        $reportingPeriodIdentifier: String
      ) {
        vehicles {
          ok
          errors {
            ...ErrorsFragment
          }
          data {
            ...VehicleFragment
            dataCalculators(
              reportingPeriodIdentifier: $reportingPeriodIdentifier
            ) {
              ...DataCalculatorResponseFragment
              measurements {
                ...MeasurementFragment
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        reportingPeriodIdentifier: activeReportingPeriodId
      }
    }
  );

  const vehicles = (data?.vehicles?.data as VehicleType[]) || [];

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [initialVehicle, setInitialVehicle] =
    useState<VehicleFragmentFragment | null>(null);

  const [areYouSureDialogOpen, setAreYouSureDialogOpen] =
    useState(false);

  const { mutate: deleteVehicle, loading: deletingVehicle } =
    useBackendMutation<
      { input: DeleteVehicleInput },
      DeleteVehiclePayload
    >({
      mutation: gql`
        mutation DeleteVehicleMutation($input: DeleteVehicleInput!) {
          deleteVehicle(input: $input) {
            ok
            errors {
              ...ErrorsFragment
            }
          }
        }
      `,
      callbacks: {
        onSuccess: () => {
          setAreYouSureDialogOpen(false);
        }
      }
    });

  const router = useRouter();

  return (
    <>
      <AssetManager<VehicleType>
        type="vehicle"
        assets={vehicles}
        loading={loading}
        beforeYouContinueMessage={
          vehicles?.length > 0
            ? null
            : 'It looks like you have not added any vehicles yet. Your carbon inventory will not be complete until you add all your vehicles.'
        }
        getAssetDetails={(vehicle) => {
          return {
            identifier: vehicle?.identifier,
            name: vehicle?.name,
            description: getVehicleDescription(vehicle),
            secondaryDescription:
              getSecondaryVehicleDescription(vehicle),
            renderIcon: () => {
              return (
                <img src={vehicle?.icon} className="grayscale" />
              );
            },
            detailSections: [
              {
                title: 'Emissions',
                renderContent: () => {
                  //Sort responses so that the ones without measurements are first
                  const responses = sortDataCalculatorResponses(
                    vehicle?.dataCalculators
                  );

                  return responses?.map((response, idx) => {
                    return (
                      <div className={idx !== 0 && 'mt-md'}>
                        <AssetMeasurementsCard
                          calculatorType={response.calculatorType}
                          measurements={
                            response.measurements as MeasurementFragmentFragment[]
                          }
                          asset={vehicle}
                        />
                      </div>
                    );
                  });
                }
              },
              {
                renderContent: vehicle?.location
                  ? () => {
                      const location = vehicle?.location;
                      const type = getLocationDescription(location);
                      const details =
                        getSecondaryLocationDescription(location);
                      return (
                        <LocationCard
                          identifier={location?.identifier}
                          name={location?.name}
                          type={type}
                          details={details}
                        />
                      );
                    }
                  : null
              }
            ]
          };
        }}
        onEdit={(vehicleIdentifier) => {
          const vehicle = vehicles.find(
            (vehicle) => vehicle.identifier === vehicleIdentifier
          );
          setInitialVehicle(vehicle);
          setCreateDialogOpen(true);
        }}
        onDelete={(vehicleIdentifier) => {
          deleteVehicle({
            variables: {
              input: {
                vehicleIdentifier
              }
            },
            awaitRefetchQueries: true,
            refetchQueries: ['VehiclesViewQuery']
          });
        }}
        deleteLoading={deletingVehicle}
        areYouSureDialogOpenState={[
          areYouSureDialogOpen,
          setAreYouSureDialogOpen
        ]}
        setCreateDialogOpen={setCreateDialogOpen}
        bottomNavButtonText="Equipment"
        bottomNavOnClick={() => {
          router.push(ROUTES.ORGANIZATION.EQUIPMENT);
        }}
      />
      <CreateVehiclesDialog
        open={createDialogOpen}
        onOpenChange={(open) => {
          setCreateDialogOpen(open);
          if (!open) setInitialVehicle(null);
        }}
        initialVehicle={initialVehicle}
      />
    </>
  );
}
