// ----------------------------------------------------------------------

import { sortDataCalculatorResponses } from '@/lib/utils';
import { gql, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useState } from 'react';
import {
  DataCalculatorResponseFragmentFragment,
  DeleteEquipmentInput,
  DeleteEquipmentPayload,
  EquipmentFragmentFragment,
  EquipmentViewQueryQuery,
  MeasurementFragmentFragment
} from 'src/__apolloGenerated__/graphql';
import AssetMeasurementsCard from 'src/components/carbon/molecules/AssetMeasurementsCard';
import AssetManager from 'src/components/carbon/organisms/AssetManager';
import { ROUTES } from 'src/config';
import useAssetStore, {
  AssetStoreType
} from 'src/hooks/store/useAssetStore';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import { fEnum } from 'src/utils/format';
import CreateEquipmentDialog from 'src/views/EquipmentView/CreateEquipmentDialog';
import {
  getLocationDescription,
  getSecondaryLocationDescription
} from 'src/views/LocationsView';
import { dataCalculatorResponseFragment } from 'src/views/Scope1View';

type EquipmentType = EquipmentFragmentFragment & {
  dataCalculators: DataCalculatorResponseFragmentFragment[];
};

export const getEquipmentDescription = (
  equipment: EquipmentFragmentFragment
) => {
  return [
    fEnum(equipment?.equipmentType) || null,
    fEnum(equipment?.fuelType) || null
  ]
    .filter(Boolean)
    .join(' • ');
};

export function LocationCard({
  identifier,
  name,
  type,
  details
}: {
  identifier: string;
  name: string;
  type: string;
  details: string;
}) {
  const router = useRouter();
  const { setActiveAssetIdentifier, setActiveAssetType } =
    useAssetStore((store: AssetStoreType) => ({
      setActiveAssetIdentifier: store.setActiveAssetIdentifier,
      setActiveAssetType: store.setActiveAssetType
    }));

  return (
    <div className="flex flex-col flex-nowrap rounded-md border p-lg">
      <div className="mb-lg ">
        <p className="subtitle2">Belongs to</p>
      </div>
      {[
        { key: 'Name', value: name },
        { key: 'Type', value: type },
        { key: 'Details', value: details }
      ].map(({ key, value }, idx) => {
        return (
          <div
            className={'flex items-center ' + (idx !== 0 && 'mt-md')}
            key={key}
            onClick={
              key === 'Name'
                ? () => {
                    router.push(ROUTES.ORGANIZATION.LOCATIONS);
                    setActiveAssetType('location');
                    setActiveAssetIdentifier(identifier);
                  }
                : null
            }
          >
            <p className="w-[80px] text-muted">{key}</p>
            <p
              className={
                key === 'Name'
                  ? 'cursor-pointer font-bold hover:underline'
                  : ''
              }
            >
              {value}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export default function EquipmentView() {
  const { activeReportingPeriodId }: SettingsStoreType =
    useSettingsStore();

  const { data, loading } = useQuery<EquipmentViewQueryQuery>(
    gql`
      ${dataCalculatorResponseFragment}
      query EquipmentViewQuery(
        $startDate: DateTime
        $endDate: DateTime
        $states: [MeasurementStatesEnum]
        $reportingPeriodIdentifier: String
      ) {
        equipment {
          ok
          errors {
            ...ErrorsFragment
          }
          data {
            ...EquipmentFragment
            dataCalculators(
              reportingPeriodIdentifier: $reportingPeriodIdentifier
            ) {
              ...DataCalculatorResponseFragment
              measurements {
                ...MeasurementFragment
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        reportingPeriodIdentifier: activeReportingPeriodId
      }
    }
  );
  const equipment = (data?.equipment?.data as EquipmentType[]) || [];

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [areYouSureDialogOpen, setAreYouSureDialogOpen] =
    useState(false);

  const [initialEquipment, setInitialEquipment] =
    useState<EquipmentFragmentFragment | null>(null);

  const { mutate: deleteEquipment, loading: deletingEquipment } =
    useBackendMutation<
      { input: DeleteEquipmentInput },
      DeleteEquipmentPayload
    >({
      mutation: gql`
        mutation DeleteEquipment($input: DeleteEquipmentInput!) {
          deleteEquipment(input: $input) {
            ok
            errors {
              ...ErrorsFragment
            }
          }
        }
      `,
      callbacks: {
        onSuccess: () => {
          setAreYouSureDialogOpen(false);
        }
      }
    });

  const router = useRouter();

  return (
    <>
      <AssetManager<EquipmentType>
        type="equipment"
        assets={equipment}
        loading={loading}
        areYouSureDialogOpenState={[
          areYouSureDialogOpen,
          setAreYouSureDialogOpen
        ]}
        deleteLoading={deletingEquipment}
        getAssetDetails={(equipment) => ({
          identifier: equipment?.identifier,
          name: equipment?.name,
          description: getEquipmentDescription(equipment),
          renderIcon: () => (
            <img src={equipment?.icon} className="grayscale" />
          ),
          detailSections: [
            {
              title: 'Emissions',
              renderContent: () => {
                //Sort responses so that the ones without measurements are first
                const responses = sortDataCalculatorResponses(
                  equipment?.dataCalculators
                );

                return responses?.map((response, idx) => {
                  return (
                    <div className={idx !== 0 && 'mt-md'}>
                      <AssetMeasurementsCard
                        calculatorType={response.calculatorType}
                        measurements={
                          response.measurements as MeasurementFragmentFragment[]
                        }
                        asset={equipment}
                      />
                    </div>
                  );
                });
              }
            },
            {
              renderContent: equipment?.location
                ? () => {
                    const location = equipment?.location;
                    const type = getLocationDescription(location);
                    const details =
                      getSecondaryLocationDescription(location);
                    return (
                      <LocationCard
                        identifier={location?.identifier}
                        name={location?.name}
                        type={type}
                        details={details}
                      />
                    );
                  }
                : null
            }
          ]
        })}
        onEdit={(equipmentIdentifier) => {
          const initialEquipment = equipment.find(
            (equipment) =>
              equipment.identifier === equipmentIdentifier
          );
          setCreateDialogOpen(true);
          setInitialEquipment(initialEquipment);
        }}
        onDelete={(equipmentIdentifier) => {
          deleteEquipment({
            variables: {
              input: {
                equipmentIdentifier
              }
            },
            refetchQueries: ['EquipmentViewQuery']
          });
        }}
        setCreateDialogOpen={setCreateDialogOpen}
        bottomNavButtonText="Scope 1"
        bottomNavOnClick={() => {
          router.push(ROUTES.TOOLS.SCOPE1);
        }}
        beforeYouContinueMessage={
          equipment?.length > 0
            ? null
            : 'It looks like you have not created any equipment yet. Make sure you add all your equipment before you continue.'
        }
      />
      <CreateEquipmentDialog
        open={createDialogOpen}
        onOpenChange={(open) => {
          setCreateDialogOpen(open);
          if (!open) setInitialEquipment(null);
        }}
        initialEquipment={initialEquipment}
      />
    </>
  );
}
