import { ExternalLink, Workflow } from 'lucide-react';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { MeasurementFragmentFragment } from 'src/__apolloGenerated__/graphql';
import CreateSubsequentMeasurementDialog from 'src/components/carbon/organisms/CreateSubsequentMeasurementDialog';
import ExpandableList from 'src/components/core/molecules/ExpandableList';
import { Button } from 'src/components/shad-base/button';
import {
  NODE_TYPES,
  ROUTES,
  getAssetTypeFromDataCalculatorType
} from 'src/config';
import useDataCalculatorStore, {
  DataCalculatorStoreType
} from 'src/hooks/store/useDataCalculatorStore';
import useMeasureStore, {
  MeasureStoreType
} from 'src/hooks/store/useMeasureStore';

export default function MeasurementLineageSection({
  measurement,
  refetchMeasurement
}: {
  measurement: MeasurementFragmentFragment;
  refetchMeasurement: () => void;
}) {
  const router = useRouter();
  const {
    setActiveAsset,
    setActiveCalculatorType,
    setActiveAssetType
  } = useDataCalculatorStore((store: DataCalculatorStoreType) => ({
    setActiveAsset: store.setActiveAsset,
    setActiveCalculatorType: store.setActiveCalculatorType,
    setActiveAssetType: store.setActiveAssetType
  }));

  const { setActiveNode } = useMeasureStore(
    (store: MeasureStoreType) => ({
      setActiveNode: store.setActiveNode
    })
  );
  const [
    createSubsequentMeasurementDialogOpen,
    setCreateSubsequentMeasurementDialogOpen
  ] = useState(false);

  const noHistory =
    measurement?.previousMeasurements?.length === 0 &&
    measurement?.subsequentMeasurements?.length === 0;

  return (
    <>
      <div className="flex flex-col flex-nowrap rounded-md border p-lg">
        <div className="mb-md flex flex-nowrap items-center justify-between">
          <div className="flex flex-nowrap items-center">
            <Workflow className="mr-sm h-5 w-5" />
            <p className="font-bold">Measurement Lineage</p>
          </div>
          {!measurement?.avoidedAfterEndDate && (
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                if (measurement?.calculatorType) {
                  const assetType =
                    getAssetTypeFromDataCalculatorType(
                      measurement?.calculatorType
                    );
                  const asset =
                    assetType === 'location'
                      ? measurement?.location
                      : assetType === 'vehicle'
                        ? measurement?.vehicle
                        : measurement?.equipment;

                  const route =
                    measurement?.scope == 1
                      ? ROUTES.TOOLS.SCOPE1
                      : ROUTES.TOOLS.SCOPE2;
                  setActiveAssetType(assetType);
                  setActiveAsset(asset);
                  setActiveCalculatorType(
                    measurement?.calculatorType
                  );
                  router.push(route);
                } else {
                  setCreateSubsequentMeasurementDialogOpen(true);
                }
              }}
            >
              {measurement?.calculatorType ? (
                <div className="flex flex-nowrap items-center">
                  <p className="body2">
                    Go to Scope {measurement?.scope} Tools
                  </p>
                  <ExternalLink className="ml-sm h-4 w-4" />
                </div>
              ) : (
                <p className="body2">Add Subsequent Measurement</p>
              )}
            </Button>
          )}
        </div>

        {noHistory ? (
          <p className="text-muted">
            No previous or subsequent measurements.
          </p>
        ) : (
          <>
            {/* Previous Measurements */}
            {measurement?.previousMeasurements?.length > 0 && (
              <div className="mb-lg">
                <ExpandableList
                  title="Previous Measurements"
                  items={measurement?.previousMeasurements?.map(
                    (measurement) => ({
                      key: measurement?.identifier,
                      label: measurement?.name,
                      onClick: () => {
                        setActiveNode({
                          nodeId: measurement?.identifier,
                          type: NODE_TYPES.MEASUREMENT,
                          path: measurement?.branch?.path
                        });
                      }
                    })
                  )}
                />
              </div>
            )}
            {/* Subsequent Measurements */}
            {measurement?.subsequentMeasurements?.length > 0 && (
              <div>
                <ExpandableList
                  title="Subsequent Measurements"
                  items={measurement?.subsequentMeasurements?.map(
                    (measurement) => ({
                      key: measurement?.identifier,
                      label: measurement?.name,
                      onClick: () => {
                        setActiveNode({
                          nodeId: measurement?.identifier,
                          type: NODE_TYPES.MEASUREMENT,
                          path: measurement?.branch?.path
                        });
                      }
                    })
                  )}
                />
              </div>
            )}
          </>
        )}
      </div>
      <CreateSubsequentMeasurementDialog
        open={createSubsequentMeasurementDialogOpen}
        onOpenChange={setCreateSubsequentMeasurementDialogOpen}
        selectedMeasurement={measurement}
        onCreateSuccess={() => {
          setCreateSubsequentMeasurementDialogOpen(false);
          refetchMeasurement();
        }}
      />
    </>
  );
}
